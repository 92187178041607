import {Box, FormControl, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import React from "react";

type AndOrButtonProps = {
    getLogic: (value: string) => void;
    logic: string;
};

const AndOrButton: React.FC<AndOrButtonProps> = ({ getLogic, logic }) => {

    const handleChange = (event: SelectChangeEvent) => {
        getLogic(event.target.value as string)
    };

    return (
        <Box>
            <FormControl>
                <Select
                    value={logic}
                    onChange={handleChange}
                >
                    <MenuItem value={"ET"}>ET</MenuItem>
                    <MenuItem value={"OU"}>OU</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

export default AndOrButton;
import {Box} from "@mui/material";
import CatalogueEntryModel, {Catalogue} from "../../models/CatalogueEntryModel";
import catalogueJson from "../../models/catalogue.json";
import LibraryTable from "./components/LibraryTable";
import SearchField from "./components/SearchField";
import AndOrButton from "./components/AndOrButton";
import {useEffect, useState} from "react";

const LibraryPage = () => {
    const generateId = (index: number): number => {
        return index + 1;
    };

    const extractKeywords = (inputString: string): string => {
        if (!inputString) return "";
        const components = inputString.split(/\s|-|\|/);
        const exclusionList = new Set(["DE", "DU", "DES", "LA", "LE", "LES"])
        return components
            .filter(word => /^[a-zA-Z]+$/.test(word) && word.length > 1)
            .map(word => word.toUpperCase())
            .filter(word => !exclusionList.has(word)).join(" ");
    }

    const getCatalogue = (catalogueJson: any): Catalogue => {
        return {
            Catalogue: catalogueJson.Catalogue.map((entry: any, index: number) => ({
                id: generateId(index),
                status: entry.Statut ?? null,
                title: entry["Titre de l'ouvrage"] ?? "",
                authors: entry["Auteur(s)"] ?? "",
                cote: entry["Cote "] ?? "",
                publication: entry.Publication ?? null,
                keywords: extractKeywords(entry["Mots clés"]),
                number: entry.Exemplaires ?? null,
            })),
        };
    }

    const [catalogue, setCatalogue] = useState(getCatalogue(catalogueJson));

    const options = ["Titre","Auteur","Publication", "Mot clé", "Cote"]

    const [logic, setLogic] = useState("ET");

    const handleLogicChange = (value: string) => {
        setLogic(value);
    };

    const [category1, setCategory1] = useState<string | null>(null);
    const [term1, setTerm1] = useState("");
    const [category2, setCategory2] = useState<string | null>(null);
    const [term2, setTerm2] = useState("");
    const [category3, setCategory3] = useState<string | null>(null);
    const [term3, setTerm3] = useState("");

    const handleGetCategory1 = (value: string | null) => {
        setCategory1(value);
        if (!value) setTerm1("");
    }
    const handleGetTerm1 = (value: string) => {
        setTerm1(value);
    }
    const handleGetCategory2 = (value: string | null) => {
        setCategory2(value);
        if (!value) setTerm2("");
    }
    const handleGetTerm2 = (value: string) => {
        setTerm2(value);
    }
    const handleGetCategory3 = (value: string | null) => {
        setCategory3(value);
        if (!value) setTerm3("");
    }
    const handleGetTerm3 = (value: string) => {
        setTerm3(value);
    }

    const filterByCategory = (
        entry: CatalogueEntryModel,
        category: string | null,
        term: string
    ): boolean => {
        if (!category) return logic === "ET";

        const valueMap: Record<string, string | null> = {
            Titre: entry.title,
            Auteur: entry.authors,
            Publication: entry.publication,
            "Mot clé": entry.keywords,
            Cote: entry.cote,
        };

        const value = valueMap[category];
        return value ? value.toUpperCase().includes(term.toUpperCase().trim()) : false;
    };

    useEffect(() => {
        let newCatalogue = getCatalogue(catalogueJson).Catalogue
        if (logic === "ET") {
            newCatalogue = newCatalogue
                .filter(entry =>
                    filterByCategory(entry, category1, term1) &&
                    filterByCategory(entry, category2, term2) &&
                    filterByCategory(entry, category3, term3))
        } else if (logic === "OU") {
            const catalogue1 = newCatalogue.filter((entry) =>
                filterByCategory(entry, category1, term1)
            );
            const catalogue2 = newCatalogue.filter((entry) =>
                filterByCategory(entry, category2, term2)
            );
            const catalogue3 = newCatalogue.filter((entry) =>
                filterByCategory(entry, category3, term3)
            );

            // Combine results and remove duplicates
            newCatalogue = Array.from(
                new Set([...catalogue1, ...catalogue2, ...catalogue3])
            );
        }
        setCatalogue({Catalogue: newCatalogue});
    }, [category1, category2, category3, term1, term2, term3, logic]);

    return (
        <Box p={2}>
            <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}}} mb={1}>
                <SearchField getCategory={handleGetCategory1} getTerm={handleGetTerm1} options={options} />
                <AndOrButton getLogic={handleLogicChange} logic={logic}/>
                <SearchField getCategory={handleGetCategory2} getTerm={handleGetTerm2} options={options} />
                <AndOrButton getLogic={handleLogicChange} logic={logic}/>
                <SearchField getCategory={handleGetCategory3} getTerm={handleGetTerm3} options={options} />
            </Box>
            <LibraryTable catalogue={catalogue}/>
        </Box>
    );
}

export default LibraryPage;
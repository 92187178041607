import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

type SearchFieldProps = {
    getCategory: (value: string | null) => void;
    getTerm: (value: string) => void;
    options: string[];
};

const SearchField: React.FC<SearchFieldProps> = ({ getCategory, getTerm, options }) => {
    const [autocompleteValue, setAutocompleteValue] = useState<string | null>(null);
    const [textFieldValue, setTextFieldValue] = useState("");
    const [textFieldDisabled, setTextFieldDisabled] = useState(true);
    const [textFieldFocused, setTextFieldFocused] = useState(false);

    useEffect(() => {
        if (autocompleteValue === null) {
            setTextFieldValue("");
            setTextFieldDisabled(true);
        }
    }, [autocompleteValue]);

    const handleAutocompleteChange = (event: React.SyntheticEvent, value: string | null) => {
        setAutocompleteValue(value);
        setTextFieldDisabled(false);
        setTextFieldFocused(true);
        getCategory(value);
    };

    const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextFieldValue(event.target.value);
        getTerm(event.target.value)
    };

    return (
        <Box display="flex" flexDirection="row" flexGrow={1}>
            <Autocomplete
                renderInput={(params) => (
                    <TextField {...params} variant="filled" sx={{ width: "170px" }} label="Limiter par" />
                )}
                options={options}
                value={autocompleteValue}
                onChange={handleAutocompleteChange}
            />
            <TextField
                variant="filled"
                value={textFieldValue}
                onChange={handleTextFieldChange}
                disabled={textFieldDisabled}
                focused={textFieldFocused}
                fullWidth
            />
        </Box>
    );
};

export default SearchField;

import {Hero} from "./components/Hero";
import {Flash} from "./components/Flash";
import {Testimonies} from "./components/Testimonies";
import {Box, Fade, Slide} from "@mui/material";
import {NewsPage} from "../News/NewsPage";
import {CookieConsent} from "react-cookie-consent";

export const HomePage = () => {

    return (
        <Box>
            <Hero/>
            <Slide direction={"up"} in={true} timeout={600}>
                <Box>
                    <Fade in={true} timeout={500}>
                        <Box>
                            <Flash/>
                        </Box>
                    </Fade>
                </Box>
            </Slide>
            <Box>
                <Testimonies/>
            </Box>
            <NewsPage/>
            <CookieConsent
                location="bottom"
                buttonText="Conscient"
                cookieName="_ga_XBPNGL45GD"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                Nous utilisons des cookies pour améliorer votre expérience.{" "}
            </CookieConsent>
        </Box>
    );
};
